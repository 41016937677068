import { XMarkIcon, HomeIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
import { TicketBundles } from "../components/TicketBundles";
import { Header } from "../components/Header";
// import heroimage from "../images/missionimpossible/Hero.png";
import { useSiteData } from "../context/SiteDataProvider";
import moreinfo from "../images/missionimpossible/moreinfo.png";
import { Logo } from "../components/Logo";
import { PFWLogo } from "../components/PFWLogo";
import { SponsorLogos } from "../components/SponsorLogos";
const { Fragment } = require("react");

const Page = () => {
  const { siteContent } = useSiteData();
  console.log("siteContent: ", siteContent);

  if (!siteContent || !siteContent.site) {
    return <div>Loading...</div>;
  }

  const {
    site: { siteName, siteDescription, siteURL, brandPrimary, brandSecondary },

    pages: {
      home: {
        meta: { title: metaTitle, description: metaDescription },
        hero,
        prizes,
        tickets,
        ticketBundles,
        body,
        cause,
      },
    },
  } = siteContent;

  return (
    <Fragment>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Header siteName={siteName} />
      <News brandSecondary={brandSecondary} />
      <Home hero={hero} brandSecondary={brandSecondary} />
      <Prizes prizes={prizes} brandSecondary={brandSecondary} />
      <TicketBundles
        title={tickets.title}
        subTitle={tickets.subTitle}
        description={tickets.description}
        bgColour={tickets.bgColour}
        textColour={tickets.textColour}
        buttonColour={tickets.buttonColour}
        ticketBundles={ticketBundles}
      />
      <BodyContent body={body} brandSecondary={brandSecondary} />
      <CauseContent cause={cause} brandSecondary={brandSecondary} />
      <FAQ />
      <Footer siteName={siteName} />
    </Fragment>
  );
};
// Helper function to get the grid class based on the number of prizes
const getPrizeGridClass = (prizeCount) => {
  let gridClass = "";
  if (prizeCount === 1) {
    gridClass = "grid-cols-1";
  } else if (prizeCount === 2) {
    gridClass = "grid-cols-2";
  } else if (prizeCount >= 3) {
    gridClass = "grid-cols-3";
  }
  return gridClass;
};

export default Page;

const News = ({ brandSecondary }) => {
  const { siteDrawData } = useSiteData();
  if (!siteDrawData) {
    return <p>Loading...</p>;
  }
  const drawEndDate = siteDrawData.length > 0 ? siteDrawData[0].drawEndDate : "No Draws Found";
  const formattedEndDate = new Date(drawEndDate).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "GMT",
  });

  return (
    <div className={`flex items-center gap-x-6 bg-[#560E3F] px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}>
      {siteDrawData.length > 0 ? (
        <p className="text-sm leading-6 text-white">The Prize Draw closes on {formattedEndDate} </p>
      ) : (
        <p className="text-sm leading-6 text-white">There are no draws currently live! Check back soon.</p>
      )}
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const Home = ({ hero, brandSecondary }) => {
  return (
    <div className="relative bg-blue-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <div className="hidden sm:mt-32 sm:flex lg:mt-16">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                {hero.causeSubHeading}{" "}
                <a href="#cause" className={`whitespace-nowrap font-semibold text-[${brandSecondary}]`}>
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">{hero.title}</h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">{hero.content}</p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="#tickets"
                className="rounded-md bg-[#64c8f5] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#64c8f5]/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
              >
                Enter the Draw
              </a>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:ml-20 xl:mr-0">
          <img className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full" src={hero.image} alt="hero image" />
        </div>
      </div>
    </div>
  );
};

// @TODO: Change this products call to Prizes API
const Prizes = ({ prizes, brandSecondary }) => {
  return (
    <div className={`bg-white text-black`} id="prizes">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h2 className="text-2xl font-bold tracking-tight">Prize Includes</h2>
          <a href="/#tickets" className="hidden text-sm font-semibold text-[#560E3F] font-medium md:block" onClick={() => document.getElementById("tickets")?.scrollIntoView()}>
            Enter the Draw <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className={`mt-6 grid grid-cols-1 gap-x-4 gap-y-10 sm:gap-x-6 md:${getPrizeGridClass(prizes.length)} md:gap-y-0 lg:gap-x-8`}>
          {prizes.map((prize) => (
            <div key={prize.id} className="group relative">
              <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                <img src={prize.imageSrc} alt={prize.imageAlt} className="h-full w-full object-cover object-center" />
              </div>
              {prize.list.map((item, index) => (
                <p key={index} className="mt-1 text-sm font-medium">
                  {item}
                </p>
              ))}
              <p className="mt-1 text-sm font-medium">{prize.price}</p>
            </div>
          ))}
        </div>

        <div className="mt-8 text-sm md:hidden">
          <a href="#" className="font-medium text-yellow-600 hover:text-yellow-500">
            Enter the Draw
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
};
const BodyContent = ({ body, brandSecondary }) => {
  return (
    <div className="overflow-hidden bg-white-grey py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className={`text-base font-semibold leading-7 text-[${brandSecondary}]`}>{body.title}</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{body.subTitle}</p>
              {body.content.map((paragraph, index) => (
                <p key={index} className="mt-2 text-lg leading-8 text-gray-600" dangerouslySetInnerHTML={{ __html: paragraph }}></p>
              ))}
            </div>
          </div>
          <div className="flex items-start justify-center sm:justify-end lg:order-first">
            <img
              src={body.image}
              alt="Product screenshot"
              className="w-full h-full object-cover sm:w-[48rem] md:w-[57rem] lg:w-[64rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CauseContent = ({ cause, brandSecondary }) => {
  return (
    <div id="cause" className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className={`text-base font-semibold leading-7 text-[#]`}>{cause.title}</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{cause.subTitle}</p>
              {cause.content.map((paragraph, index) => (
                <p key={index} className="mt-2 text-lg leading-8 text-gray-600" dangerouslySetInnerHTML={{ __html: paragraph }}></p>
              ))}
            </div>
          </div>
          <img
            src={cause.image}
            alt="Cause Image"
            className="w-full sm:w-[48rem] md:w-[57rem] lg:w-[64rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "Is there a limit on the number of draws I can participate in?",
      answer: "You are free to participate in as many prize draws as you want to. However, we urge you to play responsibility and within your means.",
    },
    {
      question: "How do you make sure prize draws are fair and transparent?",
      answer:
        "Our winners are computed and selected completely at random. Of course, your chances or winning will statistically increase if you have a greater number of entries in the draw.",
    },
    {
      question: "Are there any age restrictions for participants?",
      answer: "You must be aged 18 years or over to participate.",
    },
    {
      question: "Can I gift my winnings or tickets to someone else?",
      answer: "Yes, all entries and prizes alike can be gifted to friends, family, colleagues etc.",
    },
    {
      question: "How is my privacy protected?",
      answer:
        "All of our communications with you comply with the UK's Data Protection regulations defined by GDPR. All finances are processed by Stripe, a world leader in payment processing who also holds PCI-DDS compliance.",
    },
  ];
  return (
    <div className="bg-white" id="faqs">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? <MinusSmallIcon className="h-6 w-6" aria-hidden="true" /> : <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
function CTA() {
  return (
    <div className="bg-whiter-blue py-16 sm:py-24 lg:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-black sm:text-4xl lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block">Hear more about the prize draw.</h2>
          <br /> <p className="inline sm:block lg:inline xl:block">Sign up for our newsletter.</p>
        </div>
        <form className="w-full max-w-md text-black lg:col-span-5 lg:pt-2">
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only  text-black">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="text-black min-w-0 flex-auto rounded-md border-0 bg-white/10 px-3.5 py-2  shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-black/75 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-yellow-600 shadow-sm hover:bg-yellow-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Subscribe
            </button>
          </div>
          <p className="mt-4 text-sm leading-6 text-black">
            We care about your data. Read our{" "}
            <a href="#" className="font-semibold text-black hover:text-yellow-50">
              privacy&nbsp;policy
            </a>
            .
          </p>
        </form>
      </div>
    </div>
  );
}
const Footer = ({ siteName }) => {
  const managedSites = [
    { name: "Jimi Hendrix Draw", href: "https://jimihendrixdraw.com" },
    { name: "James Bond Draw", href: "https://jimihendrixdraw.com" },
    { name: "Mission Impossible Draw", href: "https://jimihendrixdraw.com" },
    { name: "Wildflower Draw", href: "https://jimihendrixdraw.com" },
    { name: "" },
  ];

  const navigation = {
    legal: [
      { name: "Privacy & Policy", href: "/privacy-policy" },
      { name: "Terms & Conditions", href: "/terms-conditions" },
    ],
    social: [
      {
        name: "Facebook",
        href: "https://www.facebook.com/playfundwin/",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/playfundwin/",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "X",
        href: "#",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
          </svg>
        ),
      },
    ],
  };

  return (
    <div>
      <footer className="bg-slate-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <Logo className="h-10 opacity-50" />
              <PFWLogo className="h-10" />
              <p className="text-sm leading-6 text-gray-600">{siteName} is operated by Play Fund Win.</p>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
              <div className="flex space-x-6">
                <p className="text-gray-600 hover:text-gray-500 flex flex-row gap-2">
                  <HomeIcon className="w-10" />
                  <p className="text-decoration-none text-sm black-clr branding-1-hover"> PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
                </p>
              </div>{" "}
              <div className="flex space-x-6">
                <p className="text-gray-600 hover:text-gray-500 flex flex-row gap-2">
                  <EnvelopeIcon className="w-6" />
                  <a href="mailto:hello@playfundwin.com" className="text-sm text-decoration-none black-clr branding-1-hover hover:text-gray-500">
                    {" "}
                    hello@playfundwin.com
                  </a>
                </p>
              </div>
            </div>
            <div className="mt-16 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <a href="#prizes" className="text-sm font-semibold leading-6 text-gray-900">
                    The Prize
                  </a>
                </div>
                <div className="mt-10 md:mt-0">
                  <a href="#tickets" className="text-sm font-semibold leading-6 text-gray-900">
                    Enter Draw
                  </a>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <a href="#cause" className="text-sm font-semibold leading-6 text-gray-900">
                    The Cause
                  </a>
                </div>
                <div className="mt-10 md:mt-0">
                  <a href="#faqs" className="text-sm font-semibold leading-6 text-gray-900">
                    FAQs
                  </a>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-gray-500">&copy; Play Fund Win, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
